import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import { nanoid } from "nanoid";
import DisplayContentHeader from "./DisplayContentHeader";
import { kebabCase } from "lodash";
const pluralize = require("pluralize");

class MostPopularTagsTemplate extends React.Component {
  render() {
    let remaining = [];

    // use *do not* sort a grouped response, instead this is managed through
    // code.

    let { data } = this.props;
    let tags = data;

    // let { header } = data;

    let { frontmatter: site } = data.site;

    //console.log(site.layout.columns);

    let columns = 12;

    if (site && site.layout && site.layout.columns) {
      columns = 12 / site.layout.columns;
    }

    tags = tags.tags.group;

    // pageViews sort by views
    tags = tags.sort((a, b) => {
      if (a.totalCount !== b.totalCount) {
        // sort by totalCount (desc)
        return parseInt(a.totalCount) < parseInt(b.totalCount) ? 1 : -1;
      } else {
        // sort by letter (alphabetical)
        return a.fieldValue > b.fieldValue ? 1 : -1;
      }
    });

    // segment results for visible/hidden display

    let hidden = tags.filter((posts) => posts.totalCount === 1);
    tags = tags.filter((posts) => posts.totalCount > 1);

    // limit to max of 10 results
    remaining = tags.slice(12, tags.length - 12);
    tags = tags.slice(0, 12);

    // tagconfig

    let { tagConfig } = data;

    let tagConfigs = [];

    if (tagConfig.frontmatter) {
      tagConfig.frontmatter.configTags.forEach((tag) => {
        // hero, tag required
        // title overwrites tag

        // first run lets you override tag display title

        if (!tagConfigs[tag.tag]) {
          tagConfigs[tag.tag] = {};
        }

        if (tag.tag && tag.title) {
          tagConfigs[tag.tag].safe = tag.title;
        }

        if (tag.title && !tag.tag) {
          tag.tag = tag.title;
        }

        // this is run a second time to catch any missed tagConfigs

        if (!tagConfigs[tag.tag]) {
          tagConfigs[tag.tag] = {};
          if (!tagConfigs[tag.tag].safe) {
            tagConfigs[tag.tag].safe = "";
          }
        }

        if (tag.tag) {
          if (tag.title) {
            tagConfigs[tag.tag].safe = String(tag.title).replace(
              /(^\w{1})|(\s+\w{1})/g,
              (letter) => letter.toUpperCase()
            );
          }

          if (tag.hero) {
            tagConfigs[tag.tag].hero = tag.hero;
          }
        }
      });
    }

    return (
      <div>
        <DisplayContentHeader
          title={`Blog Focus ${pluralize("Topic", tags.length)}`}
          showColon={true}
          size={4}
          weight="bold"
        />
        {tags.length ? (
          <div className="content columns is-multiline most-popular-tags">
            {tags.map((tag) => {
              let tagConfig = tagConfigs[tag.fieldValue];
              if (!tagConfig) tagConfig = {};

              let title = "";
              if (tag.fieldValue) {
                title = tag.fieldValue;
              }
              if (tagConfig.safe) {
                title = tagConfig.safe;
              }
              if (title === title.toLowerCase()) {
                title = title.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                );
              }
              return (
                <div
                  key={nanoid()}
                  className={`column is-${columns} most-popular-tags`}
                >
                  <span title={`${title} (${tag.totalCount})`}>
                    <DisplayContentHeader
                      title={title}
                      subtitle=""
                      summary={`(${tag.totalCount})`}
                      slug={`/tag/${kebabCase(tag.fieldValue)}/`}
                      weight="semibold"
                      showColon={false}
                      size={4}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <span className="no-tags hidden">No Popular Tags</span>
          </>
        )}

        {remaining.length ? (
          <>
            <br />
            <DisplayContentHeader
              title={`Related ${pluralize("Topic", remaining.length)}`}
              showColon={true}
              size={4}
              weight="bold"
            />

            <div className="content columns is-multiline">
              {remaining.map((tag) => {
                let tagConfig = tagConfigs[tag.fieldValue];
                if (!tagConfig) {
                  tagConfig = {};
                  tagConfig.safe = tag.fieldValue;
                }

                let title = "";
                if (tag.fieldValue) {
                  title = tag.fieldValue;
                }
                if (tagConfig.safe) {
                  title = tagConfig.safe;
                }
                if (title === title.toLowerCase()) {
                  title = title.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  );
                }
                return (
                  <div
                    key={nanoid()}
                    className={`column is-${columns} remaining-most-popular-tags`}
                  >
                    <span title={`${title} (${tag.totalCount})`}>
                      <DisplayContentHeader
                        title={title}
                        subtitle=""
                        summary={`(${tag.totalCount})`}
                        slug={`/tag/${kebabCase(tag.fieldValue)}/`}
                        weight="normal"
                        showColon={false}
                        size={4}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <span className="no-tags hidden">No Related Tags</span>
        )}

        {hidden.length ? (
          <>
            <div className="content hidden">
              <br />
              <div className="content columns is-multiline is-hidden">
                <DisplayContentHeader
                  title={pluralize("Topic", hidden.length)}
                  showColon={true}
                  size={4}
                  weight="semibold"
                />
                {hidden.map((tag) => {
                  let tagConfig = tagConfigs[tag.fieldValue];
                  if (!tagConfig) {
                    tagConfig = {};
                    tagConfig.safe = tag.fieldValue;
                  }

                  let title = "";
                  if (tag.fieldValue) {
                    title = tag.fieldValue;
                  }
                  if (tagConfig.safe) {
                    title = tagConfig.safe;
                  }
                  if (title === title.toLowerCase()) {
                    title = title.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                      letter.toUpperCase()
                    );
                  }
                  return (
                    <div
                      key={nanoid()}
                      className={`column is-${columns} infrequently-usee-most-popular-tags`}
                    >
                      <span title={`${title} (${tag.totalCount})`}>
                        <DisplayContentHeader
                          title={title}
                          subtitle=""
                          summary={`(${tag.totalCount})`}
                          slug={`/tag/${kebabCase(tag.fieldValue)}/`}
                          weight="normal"
                          showColon={false}
                          size={4}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <span className="no-tags hidden">No Related Tags</span>
        )}
      </div>
    );

    ///
  }
}

MostPopularTags.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  slug: PropTypes.string,
};

export default function MostPopularTags(args) {
  return (
    <StaticQuery
      query={graphql`
        query MostPopularTagsPageComponent {
          tags: allMarkdownRemark(
            limit: 1000
            sort: { fields: frontmatter___tags, order: DESC }
          ) {
            group(field: frontmatter___tags) {
              fieldValue
              totalCount
            }
          }
          site: markdownRemark(
            frontmatter: {
              advanced: {
                configKey: { eq: "site" }
                templateKey: { eq: "configuration" }
              }
            }
          ) {
            frontmatter {
              layout {
                columns
              }
            }
          }
          tagConfig: markdownRemark(
            frontmatter: {
              advanced: {
                configKey: { eq: "tags" }
                templateKey: { eq: "configuration" }
              }
            }
          ) {
            frontmatter {
              configTags {
                tag
                title
                hero {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <MostPopularTagsTemplate
          data={data}
          count={count}
          slug={args.slug}
          renderMode={args.renderMode}
          renderSize={args.renderSize}
          renderWeight={args.renderWeight}
        />
      )}
    />
  );
}

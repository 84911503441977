import * as React from "react";
import PropTypes from "prop-types";
import SmallImage from "./SmallImage";
import DisplayContentHeader from "./DisplayContentHeader";
import { Link } from "gatsby";

const DisplayTitleWithImage = ({
  smallImage,
  title,
  subtitle = "",
  showDisplayContentHeader,
  breadcrumbs,
  parent,
  parentSlug,
  grandparent,
  grandparentSlug,
  date,
  header,
  imageSlug,
}) => {
  let originalTitle = title;
  if (title && subtitle) {
    const last = title.charAt(title.length - 1);
    let colon = "";

    if (last !== "?" && last !== "!" && last !== ":") {
      colon = ":";
    }

    title = String(title + colon + " " + subtitle).trim();
  }

  return (
    <React.Fragment>
      <div className="content is-hidden-tablet">
        {imageSlug ? (
          <>
            <Link to={imageSlug} title={title}>
              <SmallImage
                image={smallImage}
                imageTitle={title}
                className="pt-0 m-0"
              />
            </Link>
          </>
        ) : (
          <SmallImage
            image={smallImage}
            imageTitle={title}
            className="pt-0 m-0"
          />
        )}
      </div>
      <div
        className={`content m-0 p-0 ${
          showDisplayContentHeader ? "" : "is-hidden"
        }`}
      >
        {imageSlug ? (
          <>
            <Link to={imageSlug} title={title}>
              <SmallImage
                image={smallImage}
                imageTitle={title}
                className="image small-image is-pulled-right pt-0 mt-0 is-hidden-mobile"
              />
            </Link>
          </>
        ) : (
          <SmallImage
            image={smallImage}
            imageTitle={title}
            className="image small-image is-pulled-right pt-0 mt-0 is-hidden-mobile"
          />
        )}
        <div className="header">
          <DisplayContentHeader
            title={originalTitle}
            breadcrumbs={breadcrumbs}
            parent={parent ? parent : false}
            parentSlug={parentSlug ? parentSlug : false}
            grandparent={grandparent ? grandparent : false}
            grandparentSlug={grandparentSlug ? grandparentSlug : false}
            date={date ? date : false}
            header={header}
            showColon={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

DisplayTitleWithImage.propTypes = {
  smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showDisplayContentHeader: PropTypes.bool,
  breadcrumbs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default DisplayTitleWithImage;

import * as React from "react";
import PropTypes from "prop-types";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
const SmallImage = ({
  image = "",
  imageTitle = "",
  className = "",
  imageClassName = "",
  link = false,
  target = "",
}) => {
  if (imageTitle) {
    imageTitle = String(imageTitle).trim();
  }
  if (image) {
    return (
      <>
        <figure className={className}>
          {link ? (
            <>
              <a title={imageTitle} href={link} target={target}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: image,
                    alt: imageTitle,
                    className: "responsive-small-image " + imageClassName,
                  }}
                />
              </a>
            </>
          ) : (
            <>
              <span title={imageTitle}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: image,
                    alt: imageTitle,
                    className: "responsive-small-image " + imageClassName,
                  }}
                />
              </span>
            </>
          )}
        </figure>
      </>
    );
  } else {
    return null;
  }
};

SmallImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageTitle: PropTypes.string,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default SmallImage;
